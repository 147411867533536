import React, { useContext, useState } from "react";
import { Context } from "../../contexts.js/Context";
import { TERipple } from "tw-elements-react";
import { errorToast, successToast } from "../../libs/toast";
import { uploadCSV } from "../../services/uploadCSV";
import { useAuth } from "../../hooks/useAuth";
import { showError } from "../../utils/showError";
import { Light } from "../../themes/Light";
import { getPaymentStatus } from "../../services/getPaymentStatus";
import LoadingModal from "../../components/LoadingModal";
import { useNavigate } from "react-router-dom";
import { FERoutes } from "../../routes/FERoutes";
import { K } from "../../constants.js/K";

export default function TableCSV() {
  // ASIN column, Sell Price column, Buy Price column
  const colors = Light;
  const auth = useAuth();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [subscription, setSubscription] = useState(null);
  const context = useContext(Context);
  const headerKeys = Object.keys(Object.assign({}, ...context.CSVData));
  const [dropdowns, setDropDowns] = useState({
    ASIN: { id: "ASIN", column: null },
    "Sell Price": { id: "Sell Price", column: null },
    "Buy Price": { id: "Buy Price", column: null },
  });
  const selectedColumns = Object.values(dropdowns).map(
    (dropdown) => dropdown.column
  );
  const handleSelectChange = (event) => {
    console.log(dropdowns);
    const id = event.target.id; // Retrieve the id attribute of the select element
    const column = event.target.value;
    const tempDropDowns = { ...dropdowns };
    tempDropDowns[id].column = column;
    setDropDowns(tempDropDowns);
  };
  const handleProceedAndPay = async () => {
    let errorDetected = false;
    for (const key in dropdowns) {
      if (dropdowns[key].column === null) {
        errorToast(`SELECTION: Please select ${key} column`);
        errorDetected = true;
      }
    }
    if (errorDetected) return;

    setShowModal(true);
    setIsLoading(true);
    const response = await getPaymentStatus(auth.authed.access_token);

    if (response.error) {
      setShowModal(false);
      showError(response.error);
    } else {
      console.log(response.subscription);
      setSubscription(response.subscription);
      setIsLoading(false);
    }
  };

  const sendCSV = async () => {
    const dataToSend = context.CSVData.map((row) => {
      const newRow = {};
      for (const key in dropdowns) newRow[key] = row[dropdowns[key].column];
      return newRow;
    });
    uploadCSV(dataToSend, auth.authed.access_token);
    navigate(FERoutes.ModelConfirmation);
    // const response = await uploadCSV(dataToSend, auth.authed.access_token);
    // if (response.error) {
    //   showError(response.error);
    // } else {
    //   successToast(
    //     "csv is uploaded on server you will recieve an email with an output"
    //   );
    // }
  };

  return (
    <div className="relative overflow-x-auto shadow-md ">
      <div
        style={{
          backgroundColor: colors.primary,
          color: colors.primaryText,
        }}
      >
        <h1 className="text-center text-xl pt-6">
          Please help us to help you! select the relevant columns
        </h1>
        <div className="flex flex-wrap gap-4 md:gap-0 justify-center py-8">
          {Object.values(dropdowns).map((dropdown, index) => (
            <div
              key={index}
              className="flex flex-row grow-1 items-center gap-8 "
            >
              <label htmlFor={dropdown.id}>Choose {dropdown.id} column:</label>
              <select
                style={{ color: colors.tertiaryText }}
                className="rounded-lg p-2"
                name={dropdown.id}
                id={dropdown.id}
                onChange={handleSelectChange}
                defaultValue={""}
              >
                <option value="" id="" disabled>
                  Select a column
                </option>
                {headerKeys.map((key, index) => (
                  <option key={index} value={key}>
                    {key}
                  </option>
                ))}
              </select>
            </div>
          ))}
          <TERipple
            rippleColor="light"
            className="w-full flex flex-row justify-center"
          >
            <button
              onClick={handleProceedAndPay}
              className="inline-block self-center rounded px-6 pb-2 pt-2 text-xs font-medium uppercase leading-normal shadow-[0_4px_9px_-4px_rgba(0,0,0,0.2)] transition duration-150 ease-in-out hover:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)] focus:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)] focus:outline-none focus:ring-0 active:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)]"
              type="button"
              style={{
                backgroundColor: colors.secondary,
                color: colors.secondaryText,
              }}
            >
              proceed
            </button>
          </TERipple>
        </div>
      </div>
      <div className="w-full overflow-auto">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              {headerKeys.map((key, index) => (
                <th key={index} scope="col" className="px-3 py-3">
                  {key}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {context.CSVData.map((row, index) => (
              <tr
                key={index}
                className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700"
              >
                {headerKeys.map((key, index) =>
                  selectedColumns.includes(key) ? (
                    <td
                      key={index}
                      className="px-6 py-4 bg-green-600 text-white"
                    >
                      {row[key]}
                    </td>
                  ) : (
                    <td key={index} className="px-6 py-4">
                      {row[key]}
                    </td>
                  )
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <LoadingModal
        showModal={showModal}
        setShowModal={setShowModal}
        isLoading={isLoading}
        loadingTitle={"Checking Subscription Status"}
        doneTitle={
          subscription
            ? "You have a valid subscription"
            : "You do not have a valid subscription"
        }
        showTick={subscription ? true : false}
        actionHandlerTitle={subscription ? "continue" : "subscribe"}
        actionHandler={
          subscription
            ? sendCSV
            : () => {
                navigate(FERoutes.Payment);
              }
        }
      />
    </div>
  );
}
