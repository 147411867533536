import { useNavigate } from "react-router-dom";
import logoPNG from "../../assets/images/logo.png";
import { FERoutes } from "../../routes/FERoutes";

export default function ModelConfirmation() {
  const navigate = useNavigate();
  return (
    <div
      id="successModal"
      // tabindex="-1"
      // aria-hidden="true"
      class="flex flex-col justify-center items-center h-screen"
    >
      <div class="relative p-4 w-full max-w-md h-full md:h-auto">
        <img className="mx-auto w-72 " src={logoPNG} />
        <div class="relative p-4 text-center bg-white sm:p-5">
          {/* <div class="w-12 h-12 rounded-full bg-green-100 p-2 flex items-center justify-center mx-auto mb-3.5">
            <svg
              aria-hidden="true"
              class="w-8 h-8 text-green-500"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <span class="sr-only">Success</span>
          </div> */}
          <p class="mb-4 text-lg font-semibold text-gray-900">
            Model is successfully started.
          </p>
          <p class="mb-4 text-lg font-semibold text-gray-900">
            You will recieve result in email shortly.
          </p>
          <button
            onClick={() => navigate(FERoutes.UploadCSV)}
            data-modal-toggle="successModal"
            type="button"
            class="py-2 px-3 text-sm font-medium text-center text-white rounded-lg bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-30"
          >
            UPLOAD ANOTHER FILE
          </button>
        </div>
      </div>
    </div>
  );
}
