import { BERoutes } from "../routes/BERoutes";

export const getPaymentStatus = async (bearer) => {
  try {
    const endpoint = `${process.env.REACT_APP_API_URL}${BERoutes.paymentStatus}`;

    const response = await fetch(endpoint, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${bearer}`,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      const errorData = await response.json();
      return { error: errorData.detail };
    }

    const successData = await response.json(); // Parse the JSON response
    // Handle the data received from the server
    return successData;
  } catch (error) {
    // Handle errors
    console.error("Error (getPaymentStatus)", error);
    return { error: "Error (getPaymentStatus)" };
  }
};
