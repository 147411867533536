import React, { useContext, useRef } from "react";
import { useState } from "react";
import { Context } from "../../contexts.js/Context";
import { useNavigate } from "react-router-dom";
import { FERoutes } from "../../routes/FERoutes";
import Papa from "papaparse";
import { Light } from "../../themes/Light";
import logoPNG from "../../assets/images/logo.png";

export default function UploadCSV() {
  const colors = Light;
  const context = useContext(Context);
  const navigate = useNavigate();
  const fileReader = new FileReader();

  const [dragActive, setDragActive] = useState(false);
  const inputRef = useRef(null);
  const [file, setFile] = useState([]);

  const handleOnChange = (file) => {
    if (file) {
      fileReader.onload = function (event) {
        const text = event.target.result;
        csvFileToArray(text);
      };

      fileReader.readAsText(file);
    }
  };

  const csvFileToArray = (string) => {
    const parsedData = Papa.parse(string, {
      header: true, // Treats the first row as header
      skipEmptyLines: true, // Skip empty lines
    });
    context.setCSVData(
      parsedData.data.filter((row) => {
        for (const key in row) {
          if (row[key] && row[key] !== "\r") return true;
        }
        return false;
      })
    );
    navigate(FERoutes.TableCSV);
  };

  function handleChange(e) {
    e.preventDefault();
    handleOnChange(e.target.files[0]);
  }

  function handleSubmitFile(e) {
    if (file.length === 0) {
      // no file has been submitted
    } else {
      // write submit logic here
    }
  }

  function handleDrop(e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    handleOnChange(e.dataTransfer.files[0]);
  }

  function handleDragLeave(e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
  }

  function handleDragOver(e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(true);
  }

  function handleDragEnter(e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(true);
  }

  function openFileExplorer() {
    inputRef.current.value = "";
    inputRef.current.click();
  }

  return (
    <div className="flex flex-col items-center justify-center w-full p-10 h-screen">
      <img className="my-16 w-72" src={logoPNG} />
      <div className="flex items-center justify-center h-screenflex flex-col items-center w-full h-full border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
        <form
          style={{
            backgroundColor: dragActive ? colors.secondary : colors.primary,
          }}
          className={
            "h-full w-full flex flex-col items-center justify-center rounded-lg"
          }
          onDragEnter={handleDragEnter}
          onSubmit={(e) => e.preventDefault()}
          onDrop={handleDrop}
          onDragLeave={handleDragLeave}
          onDragOver={handleDragOver}
          onClick={openFileExplorer}
        >
          {/* this input element allows us to select files for upload. We make it hidden so we can activate it when the user clicks select files */}
          <input
            placeholder="fileInput"
            className="hidden"
            ref={inputRef}
            type="file"
            multiple={true}
            onChange={handleChange}
            accept={".csv"}
          />

          <p className="mb-2" style={{ color: colors.primaryText }}>
            <span className="font-semibold">Click to upload</span> or drag and
            drop
          </p>
          <p style={{ color: colors.primaryText }}>CSV</p>
          {/* <div className="flex flex-col items-center p-3">
            {file.map((file, idx) => (
              <div key={idx} className="flex flex-row space-x-5">
                <span>{file.name}</span>
                <span
                  className="text-red-500 cursor-pointer"
                  onClick={() => removeFile(file.name, idx)}
                >
                  remove
                </span>
              </div>
            ))}
          </div> */}

          {/* <button
          className="bg-black rounded-lg p-2 mt-3 w-auto"
          onClick={handleSubmitFile}
        >
          <span className="p-2 text-white">Submit</span>
        </button> */}
        </form>
      </div>
    </div>
  );
}
