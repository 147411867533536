import { BrowserRouter, Routes, Route } from "react-router-dom";
import SignIn from "./views/auth/SignIn";
import SignUp from "./views/auth/SignUp";
import { FERoutes } from "./routes/FERoutes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UploadCSV from "./views/uploadCSV/UploadCSV";
import { ProtectedRoute } from "./components/ProtectedRoute";
import TableCSV from "./views/uploadCSV/TableCSV";
import { Context } from "./contexts.js/Context";
import { useState } from "react";
import Payment from "./views/payment/Payment";
import ModelConfirmation from "./views/confirmations/ModelConfirmation";

export default function App() {
  const [CSVData, setCSVData] = useState([]);
  return (
    <Context.Provider value={{ CSVData, setCSVData }}>
      <BrowserRouter>
        <ToastContainer />
        <Routes>
          <Route path={FERoutes.SignIn} element={<SignIn />} />
          <Route path={FERoutes.SignUp} element={<SignUp />} />
          <Route
            path={FERoutes.UploadCSV}
            element={
              <ProtectedRoute>
                <UploadCSV />
              </ProtectedRoute>
            }
          />
          <Route
            path={FERoutes.TableCSV}
            element={
              <ProtectedRoute>
                <TableCSV />
              </ProtectedRoute>
            }
          />
          <Route
            path={FERoutes.Payment}
            element={
              <ProtectedRoute>
                <Payment />
              </ProtectedRoute>
            }
          />
          <Route
            path={FERoutes.ModelConfirmation}
            element={
              <ProtectedRoute>
                <ModelConfirmation />
              </ProtectedRoute>
            }
          />

          <Route path="/" element={<SignIn />} />
        </Routes>
      </BrowserRouter>
    </Context.Provider>
  );
}
