import React, { useEffect, useState } from "react";
import { getPaymentPlans } from "../../services/getPaymentPlans";
import { createCheckoutSession } from "../../services/createCheckoutSession";
import { useAuth } from "../../hooks/useAuth";
import { showError } from "../../utils/showError";
import LoadingModal from "../../components/LoadingModal";
import { K } from "../../constants.js/K";
import { useNavigate } from "react-router-dom";
import { getPaymentStatus } from "../../services/getPaymentStatus";

export default function Payment() {
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [paymentPlans, setPaymentPlans] = useState([]);
  const auth = useAuth();

  useEffect(() => {
    fetchPaymentPlans();
  }, []);

  useEffect(() => {
    const handleVisibilityChange = async () => {
      if (document.visibilityState === "visible") {
        const response = await getPaymentStatus(auth.authed.access_token);

        if (response.error) {
        } else {
          if (response.subscription) navigate(-1);
        }
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  const fetchPaymentPlans = async () => {
    const res = await getPaymentPlans();
    setPaymentPlans(res);
  };

  const onClickGetStarted = async (paymentPlan) => {
    localStorage.setItem(K.subscriptionStatus, false);

    setShowModal(true);
    setIsLoading(true);

    const response = await createCheckoutSession(
      paymentPlan,
      auth.authed.access_token
    );

    if (response.error) {
      setShowModal(false);
      showError(response.error);
    } else {
      setIsLoading(false);

      setTimeout(() => {
        setShowModal(false);
        window.open(response.url, "_blank", "noreferrer");
      }, 3000);

      // const intervalId = setInterval(() => {
      //   const subscriptionStatus = localStorage.getItem(K.subscriptionStatus);
      //   console.log("subscriptionStatus", subscriptionStatus);
      //   if (subscriptionStatus === true) {
      //     localStorage.removeItem(K.subscriptionStatus);
      //     navigate(-1);
      //     clearInterval(intervalId);
      //   }
      // }, 1000);
    }
  };

  return (
    <section className="bg-white dark:bg-gray-900">
      <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
        <div className="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
          <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
            Designed for your ease
          </h2>
          <p className="mb-5 font-light text-gray-500 sm:text-xl dark:text-gray-400">
            Our team of experts has created the ideal product analyzer. We will
            help you generate 60% more income with new opportunities, and we aim
            to identify the most profitable conversion products with estimated
            sales forecasts.
          </p>
        </div>
        <div className="space-y-8 lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-10 lg:space-y-0">
          {paymentPlans.map((paymentPlan, index) => (
            <div
              key={index}
              className="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white"
            >
              <h3 className="mb-4 text-2xl font-semibold">
                {paymentPlan.name}
              </h3>
              {/* <p className="font-light text-gray-500 sm:text-lg dark:text-gray-400">
                Best option for personal use & for your next project.
              </p> */}
              <div className="flex justify-center items-baseline my-8">
                <span className="mr-2 text-5xl font-extrabold">
                  ${paymentPlan.price}
                </span>
                <span className="text-gray-500 dark:text-gray-400">/month</span>
              </div>
              <ul role="list" className="mb-8 space-y-4 text-left">
                <li className="flex items-center space-x-3">
                  {/* <svg
                    className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    ></path>
                  </svg> */}
                  <span>
                    {paymentPlan.rowsAllowedPerDay} of row allowed per day
                  </span>
                </li>
              </ul>
              <a
                // href="#"
                onClick={() => onClickGetStarted(paymentPlan)}
                className="text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:text-white  dark:focus:ring-primary-900"
              >
                Get started
              </a>
            </div>
          ))}
        </div>
      </div>
      <LoadingModal
        showModal={showModal}
        isLoading={isLoading}
        loadingTitle={"Generating Payment Link"}
        doneTitle={"Generated Payment Link"}
        showTick={true}
      />
    </section>
  );
}
