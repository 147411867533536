import * as React from "react";
import { useCookies } from "react-cookie";
import { Cookies } from "../constants.js/Cookies";

const authContext = React.createContext();

export function useAuth() {
  const [cookies, setCookie, removeCookie] = useCookies([Cookies.cookiesName]);

  return {
    authed: cookies[Cookies.user],
    login(user) {
      return new Promise((res) => {
        // setAuthed(true);
        setCookie(Cookies.user, user);
        res();
      });
    },
    logout() {
      return new Promise((res) => {
        // setAuthed(false);
        removeCookie(Cookies.user);
        res();
      });
    },
  };
}

export function AuthProvider({ children }) {
  const auth = useAuth();

  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export default function AuthConsumer() {
  return React.useContext(authContext);
}
