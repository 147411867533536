import { BERoutes } from "../routes/BERoutes";

export const signInUser = async (data) => {
  try {
    const endpoint = `${process.env.REACT_APP_API_URL}${BERoutes.tokenUser}`;

    const response = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: new URLSearchParams(data), // Convert JavaScript object to JSON string
    });

    if (!response.ok) {
      const errorData = await response.json();
      return { error: errorData.detail };
    }

    const successData = await response.json(); // Parse the JSON response
    // Handle the data received from the server
    return successData;
  } catch (error) {
    // Handle errors
    console.error("Error (signInUser)", error);
    return { error: "Error (signInUser)" };
  }
};
